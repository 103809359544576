body {
    font-family: 'Yantramanav', sans-serif;
}

/*!
 * Start Bootstrap - Resume v5.0.8 (https://startbootstrap.com/template-overviews/resume)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-resume/blob/master/LICENSE)
 */

@media (min-width: 992px) {
    body {
        padding-top: 0;
        padding-left: 17rem;
    }
}

html {
    scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    text-transform: uppercase;
    color: #343a40;
}

h1 {
    font-size: 6rem;
    line-height: 5.5rem;
}

h2 {
    font-size: 3.5rem;
}

h3 {
    font-size: 2rem;
}

p.lead {
    font-size: 1.15rem;
    font-weight: 400;
}

.subheading {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.5rem;
}

.social-icons a {
    display: inline-block;
    height: 3.5rem;
    width: 3.5rem;
    background-color: #495057;
    color: #fff !important;
    border-radius: 100%;
    text-align: center;
    font-size: 1.5rem;
    line-height: 3.5rem;
    margin-right: 1rem;
}

.social-icons a:last-child {
    margin-right: 0;
}

.social-icons a:hover {
    background-color: #BD5D38;
}

.dev-icons {
    font-size: 3rem;
}

.dev-icons .list-inline-item i:hover {
    color: #BD5D38;
}

#nav-image {
    display: none;
}

.spacer {
    margin-top: 225px;
}

#sideNav .navbar-nav .nav-item .nav-link {
    font-weight: 800;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
}

#sideNav .navbar-toggler:focus {
    outline-color: #d48a6e;
}

@media (min-width: 992px) {
    .ml-5 {
        margin-left: 0% !important;
    }
    #sideNav {
        text-align: center;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 17rem;
        height: 100vh;
    }
    #sideNav .navbar-brand {
        display: flex;
        margin: auto auto 0;
        padding: 0.5rem;
    }
    #sideNav .navbar-brand .img-profile {
        max-width: 10rem;
        max-height: 10rem;
        border: 0.5rem solid rgba(255, 255, 255, 0.2);
    }
    #sideNav .navbar-collapse {
        display: flex;
        align-items: flex-start;
        flex-grow: 0;
        width: 100%;
        margin-bottom: auto;
    }
    #sideNav .navbar-collapse .navbar-nav {
        flex-direction: column;
        width: 100%;
    }
    #sideNav .navbar-collapse .navbar-nav .nav-item {
        display: block;
    }
    #sideNav .navbar-collapse .navbar-nav .nav-item .nav-link {
        display: block;
    }
}

section.resume-section {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
    max-width: 75rem;
}

section.resume-section .resume-item .resume-date {
    min-width: none;
}

@media (min-width: 768px) {
    section.resume-section {
        min-height: 100vh;
    }
    section.resume-section .resume-item .resume-date {
        min-width: 18rem;
    }
}

@media (max-width: 768px) {
    body {
        text-align: center;
    }
}

@media (min-width: 992px) {
    section.resume-section {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
}

.bg-primary {
    background-color: #BD5D38 !important;
}

.text-primary {
    color: #BD5D38 !important;
}

a {
    color: #BD5D38;
}

a:hover, a:focus, a:active {
    color: #824027;
}

